<template>
  <!-- v-if needs to be here, bc filters and column settings depend on the landingpage types  -->
  <div
    v-if="landingpages"
    class="full-size-container h-full"
  >
    <BaseHeader
      :title="$t('leads.title')"
      :style-variant="8"
    >
      <template v-slot:design>
        <HeaderDesign />
      </template>

      <template v-slot:desc>
        <p>{{ $t('leads.subtitle') }}</p>
      </template>
    </BaseHeader>
    <LeadList
      :leads="preparedLeads"
      :loading="$apollo.queries.leads.loading"
      :server-items-length="leads.total"
      :items-per-page="itemsPerPage"
      :refetch-leads="refetchLeads"
      :disable-filters="isOnlyAssignedToMe"
      :disable-lead-import="!canImportLeads"
      :landingpage-types="landingpages"
      :select-items="selectLead"
      :is-lead-import-table="canImportLeads"
      :selected-lead-ids="selectedLeadIds"
      :parent-trigger-select-all-items="triggerSelectAllItems"
      :trigger-reset-delete-leads="triggerResetDeleteLeads"
      :trigger-reset-assign-leads="triggerResetAssignLeads"
      :select-all-leads-state="selectAllLeadsState"
      :is-deleting-leads="isDeletingLeads"
      :is-assigning-leads="isAssigningLeads"
      @allItemsSelected="allItemsSelected"
      @updateFetchIdsParameters="updateFetchIdsParameters"
      @invitationSent="invitationSent"
      @deleteLeads="deleteLeads"
      @assignLeads="assignLeads"
      @triggeredSelectAllItems="triggeredSelectAllItems"
      @triggeredResetDeleteLeads="triggeredResetDeleteLeads"
      @triggeredResetAssignLeads="triggeredResetAssignLeads"
    />
  </div>
</template>

<script>
import LEADS from './queries/Leads.gql'
import ASSIGN_LEADS from './queries/AssignLeads.gql'
import DELETE_LEADS from '@/modules/leads/queries/DeleteLeads.gql'
import LANDINGPAGES from './Landingpages.gql'
import ACQUISITION_BOOSTER_LEAD_IDS from './queries/AcquisitionBoosterLeadIds.gql'
import LEAD_IDS from './queries/LeadIds.gql'
import BaseHeader from '@/components/BaseHeader.vue'
import bus, { eventNames } from '@/lib/eventBus'
import HeaderDesign from './HeaderDesign.vue'
import { InvitationContactFilter } from './enums/Invitation'
import featureMixin from '@/mixins/feature'
import { DeleteLeadsContactFilter } from './enums/DeleteLeads'

export default {
  components: {
    LeadList: () => import('./LeadList'),
    BaseHeader,
    HeaderDesign
  },
  mixins: [featureMixin],
  props: {
    isOnlyAssignedToMe: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      leads: {
        total: 0,
        entries: []
      },
      sortBy: 'lastConversionAt',
      sortDesc: true,
      page: 1,
      itemsPerPage: 20,
      search: '',
      filters: {
        currentStatus: null,
        assignedUser: null,
        valuations: false,
        returnCalls: false,
        lifeAnnuities: false,
        partialSales: false,
        leadOriginConversion: true,
        leadOriginImport: true
      },
      landingpageSlugFilter: '',

      selectAllLeadsState: 'idle',
      selectedLeadIds: [],

      isDeletingLeads: false,
      isAssigningLeads: false,

      company: {},

      triggerSelectAllItems: false,
      triggerResetAssignLeads: false,
      triggerResetDeleteLeads: false
    }
  },
  apollo: {
    leads: {
      query: LEADS,
      variables () {
        return {
          landingpageSlug: this.landingpageSlugFilter,
          input: {
            companyId: this.$auth.user.companyId,
            onlyAssignedToMe: this.isOnlyAssignedToMe,
            sortBy: this.sortBy,
            sortDesc: this.sortDesc,
            page: this.page,
            itemsPerPage: this.itemsPerPage,
            search: this.search,
            filters: Object.assign({}, this.filters)
          }
        }
      },
      result () {
        this.triggerSelectAllItems = false
      }
    },
    company: {
      query: LANDINGPAGES,
      variables () {
        return {
          id: this.$auth.user.companyId
        }
      }
    }
  },
  computed: {
    landingpages () {
      if (!this.company) return
      return this.company.landingpages
    },

    preparedLeads () {
      if (!this.leads) return
      return this.leads.entries
    },
    canImportLeads () {
      return this.isFeatureActive(this.featureNames.LEAD_IMPORT)
    }
  },
  methods: {
    triggeredResetDeleteLeads () {
      this.triggerResetDeleteLeads = false
    },
    triggeredResetAssignLeads () {
      this.triggerResetAssignLeads = false
    },
    triggeredSelectAllItems () {
      this.triggerSelectAllItems = false
    },
    refetchLeads ({ sortBy = this.sortBy, sortDesc = this.sortDesc, page = this.page, itemsPerPage = this.itemsPerPage, search = this.search, filters = this.filters, landingpageSlugFilter = this.landingpageSlugFilter }) {
      // cancel pending call
      clearTimeout(this._timerId)

      this.page = page
      this.sortBy = sortBy
      this.sortDesc = sortDesc
      this.itemsPerPage = itemsPerPage
      this.filters = filters
      this.landingpageSlugFilter = landingpageSlugFilter

      // delay new call 1000ms
      this._timerId = setTimeout(() => {
        this.search = search
        this.$apollo.queries.leads.refetch()
      }, 1000)
    },

    async invitationSent () {
      this.selectedLeadIds = []
      this.landingpageSlugFilter = ''
      await this.refetchLeads({})
    },

    async deleteLeads () {
      this.isDeletingLeads = true

      if (this.selectedLeadIds.length) {
        try {
          await this.$apollo.mutate({
            mutation: DELETE_LEADS,
            variables: {
              input: {
                leadIds: this.selectedLeadIds
              }
            }
          })
          this.selectedLeadIds = []
          this.triggerResetDeleteLeads = true
          await this.refetchLeads({})
          bus.$emit(eventNames.SHOW_ANIMATION)
          bus.$emit(eventNames.SHOW_SNACKBAR, { color: 'success', text: this.$t('alerts.delete-leads.success') })
        } catch (error) {
          bus.$emit(eventNames.SHOW_SNACKBAR, { color: 'error', text: this.$t('alerts.delete-leads.error') })
          // eslint-disable-next-line no-console
          console.log(error)
        } finally {
          this.isDeletingLeads = false
        }
      }
    },

    async assignLeads (userId) {
      this.isAssigningLeads = true

      if (this.selectedLeadIds.length) {
        try {
          await this.$apollo.mutate({
            mutation: ASSIGN_LEADS,
            variables: {
              input: {
                userId,
                leadIds: this.selectedLeadIds
              }
            }
          })
          this.selectedLeadIds = []
          this.triggerResetAssignLeads = true
          await this.refetchLeads({})
          bus.$emit(eventNames.SHOW_ANIMATION)
          bus.$emit(eventNames.SHOW_SNACKBAR, { color: 'success', text: this.$t('alerts.assign-leads.success') })
        } catch (error) {
          bus.$emit(eventNames.SHOW_SNACKBAR, { color: 'error', text: this.$t('alerts.assign-leads.error') })
        } finally {
          this.isAssigningLeads = false
        }
      }
    },

    selectLead (items = []) {
      this.selectedLeadIds = []
      items.forEach(item => {
        if (!item.invitationBlockedReason) {
          this.selectedLeadIds.push(item.id)
        }
      })
      if (this.selectAllLeadsState === 'selected') this.selectAllLeadsState = 'idle'
    },

    async allItemsSelected ({ isSelectAll, slug }) {
      this.selectAllLeadsState = 'loading'

      if (isSelectAll) {
        try {
          if (slug === 'invitation') {
            const { data: { getAcquisitionBoosterLeadIds } } = await this.$apollo.mutate({
              mutation: ACQUISITION_BOOSTER_LEAD_IDS,
              variables: {
                input: {
                  companyId: this.$auth.user.companyId,
                  onlyAssignedToMe: this.isOnlyAssignedToMe,
                  search: this.search,
                  landingpageSlug: this.landingpageSlugFilter,
                  filters: Object.assign({}, this.filters)
                }
              }

            })
            this.selectedLeadIds = getAcquisitionBoosterLeadIds.leadIds
          } else if (['deleteLeads', 'assignLeads'].includes(slug)) {
            const { data: { leadIds } } = await this.$apollo.mutate({
              mutation: LEAD_IDS,
              variables: {
                input: {
                  companyId: this.$auth.user.companyId,
                  onlyAssignedToMe: this.isOnlyAssignedToMe,
                  search: this.search,
                  filters: Object.assign({}, this.filters)
                }
              }
            })
            this.selectedLeadIds = leadIds.leadIds
          }
          this.selectAllLeadsState = 'selected'
        } catch (err) {
          this.selectAllLeadsState = 'idle'
          bus.$emit(eventNames.SHOW_SNACKBAR, { color: 'error', text: this.$t('alerts.delete-leads.error-selection') })
        }
      } else {
        this.selectedLeadIds = []
        this.selectAllLeadsState = 'idle'
      }
    },

    async updateFetchIdsParameters ({ contactFilter, landingpageSlug, filters = {}, activeTool }) {
      if (activeTool === 'invitation') this.updateInvitationParameters({ contactFilter, landingpageSlug, filters })
      if (activeTool === 'deleteLeads') this.updateDeleteLeadsParameter({ contactFilter, filters })
    },

    async updateInvitationParameters ({ contactFilter, landingpageSlug, filters = {} }) {
      if (!contactFilter || !landingpageSlug) return
      this.landingpageSlugFilter = landingpageSlug
      if (contactFilter === InvitationContactFilter.CUSTOM) {
        this.selectedLeadIds = []
      } else {
        try {
          const { data: { getAcquisitionBoosterLeadIds } } = await this.$apollo.mutate({
            mutation: ACQUISITION_BOOSTER_LEAD_IDS,
            variables: {
              input: {
                companyId: this.$auth.user.companyId,
                landingpageSlug,
                filters: Object.assign({}, filters)
              }
            }
          })
          this.selectedLeadIds = getAcquisitionBoosterLeadIds.leadIds
        } catch (err) {
          bus.$emit(eventNames.SHOW_SNACKBAR, { color: 'error', text: this.$t('alerts.delete-leads.error-selection') })
        }
      }
    },

    async updateDeleteLeadsParameter ({ contactFilter, filters = {} }) {
      if (!contactFilter) return
      if (contactFilter === DeleteLeadsContactFilter.CUSTOM) {
        this.selectedLeadIds = []
      } else {
        try {
          const { data: { getDeleteLeadsLeadIds } } = await this.$apollo.mutate({
            mutation: LEAD_IDS,
            variables: {
              input: {
                companyId: this.$auth.user.companyId,
                filters: Object.assign({}, filters)
              }
            }
          })
          this.selectedLeadIds = getDeleteLeadsLeadIds.leadIds
        } catch (err) {
          bus.$emit(eventNames.SHOW_SNACKBAR, { color: 'error', text: this.$t('alerts.delete-leads.error-selection') })
        }
      }
    }
  },
  beforeUnmount () {
    clearTimeout(this._timerId)
  }
}
</script>
