<template>
  <v-row>
    <v-col
      cols="5"
      md="4"
      xl="5"
      class="pr-0 mr-0"
    >
      <v-select
        v-model="countryCallingCode"
        :items="countryCallingCodes"
        :disabled="disabled"
        :label="$t('labels.country-calling-code')"
        outlined
        item-text="displayText"
        item-value="countryCallingCode"
        hide-details="auto"
        required
        :rules="[(v) => !!v || $t('labels.country-calling-code-required')]"
        :selectable="countryCallingCode => countryCallingCode.disabled"
        @change="updateFullPhoneNumber"
      />
    </v-col>
    <v-col
      cols="7"
      md="8"
      xl="7"
    >
      <v-text-field
        v-if="phoneValidation"
        v-model="nationalNumber"
        outlined
        :rules="validationRules"
        :label="label || $t('labels.phone-number')"
        :hint="hint"
        type="tel"
        hide-details="auto"
        :disabled="disabled"
        :validate-on-blur="validateOnBlur"
        :error-messages="errorMessage"
        :loading="loading"
        @blur="updateFullPhoneNumber"
        @input="updateFullPhoneNumberOnChange"
      />
    </v-col>
  </v-row>
</template>

<script>
import { phoneStripped, hasNumericalSequence } from '@/lib/validation'
import codes from '@/lib/countryCallingCodes'

export default {
  inject: ['companyBaseData'],
  props: {
    value: {
      type: String,
      default: ''
    },
    label: {
      type: String,
      default: ''
    },
    hint: {
      type: String,
      default: 'Format 753112345'
    },
    disabled: {
      type: Boolean,
      default: false
    },
    rules: {
      type: Array,
      default: () => []
    },
    errorMessage: {
      type: String,
      default: ''
    },
    validateOnBlur: {
      type: Boolean,
      default: false
    },
    updateOnInput: {
      type: Boolean,
      default: false
    },
    loading: {
      type: Boolean,
      default: false
    },
    validatePhone: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      countryCallingCode: '',
      nationalNumber: '',
      validationRules: [phoneStripped, this.validateWholePhoneNumber, ...this.rules],
      phoneValidation: null,
      codes
    }
  },
  computed: {
    countryCallingCodes () {
      const mostRelevantCountryCodes = ['DE', 'AT', 'CH']
      const mostRelevantEntries = []
      const countries = codes
        .map(country => {
          const entry = { countryCode: country.countryCode, countryCallingCode: country.callingCode, displayText: `${country.callingCode} (${country.countryCode})`, disabled: false }
          if (mostRelevantCountryCodes.includes(country.countryCode)) {
            mostRelevantEntries.push(entry)
            return null
          }
          return entry
        })
        .filter(x => x !== null)
        .sort((a, b) => a.countryCode.localeCompare(b.countryCode))

      const seperator = { countryCode: '-', countryCallingCode: '-', displayText: '────', disabled: true }
      countries.unshift(seperator)
      countries.unshift(...mostRelevantEntries)

      return countries
    }
  },
  watch: {
    value () {
      this.setSplittedValues()
    }
  },
  async created () {
    this.setSplittedValues()
    const libphonenumber = () => import(/* webpackChunkName: "libphonenumber-js/max" */'libphonenumber-js/max')
    this.phoneValidation = await libphonenumber()
  },
  methods: {
    setSplittedValues () {
      const { countryCallingCode, nationalNumber } = this.getSplittedPhoneNumber()
      this.countryCallingCode = countryCallingCode.trim()
      this.nationalNumber = nationalNumber.trim()
    },

    getSplittedPhoneNumber () {
      if (this.value) {
        return this.parseValueToSplittedFormat()
      }

      const countryCallingCode = this.getCountryCallingCodeForCompany()

      return {
        countryCallingCode,
        nationalNumber: ''
      }
    },
    validateWholePhoneNumber () {
      const { countryCallingCode, nationalNumber } = this.getSplittedPhoneNumber()
      if (this.validatePhone && nationalNumber) {
        const phone = '+' + countryCallingCode + nationalNumber

        return (!hasNumericalSequence(phone) && this.phoneValidation.isValidPhoneNumber(phone)) || this.$t('labels.phone-invalid')
      }
      return true
    },

    updateFullPhoneNumberOnChange () {
      if (this.updateOnInput) {
        this.updateFullPhoneNumber()
      }
    },

    updateFullPhoneNumber () {
      const symbol = this.countryCallingCode && '+'
      let combinedPhone = ''
      if (this.nationalNumber) {
        combinedPhone = `${symbol}${this.countryCallingCode}${this.nationalNumber}`.trim()
      }
      this.$emit('input', combinedPhone)
    },

    parseValueToSplittedFormat () {
      const mappedCodes = this.countryCallingCodes.map(entry => entry.countryCallingCode).join('|')
      var regex = new RegExp('^((00|\\+)(?<countryCallingCode>' + mappedCodes + '))?(?<nationalNumber>.*)')

      const { groups } = this.value.match(regex)
      const { countryCallingCode = '', nationalNumber = this.value } = groups

      return {
        countryCallingCode,
        nationalNumber
      }
    },

    getCountryCallingCodeForCompany () {
      let code = this.countryCallingCodes[0].countryCallingCode
      if (this.companyBaseData && this.companyBaseData.country) {
        code = this.countryCallingCodes.find((country) => (country.countryCode === this.companyBaseData.country.toUpperCase())).countryCallingCode
      }
      return code
    }

  }

}
</script>
